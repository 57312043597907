import React from "react"
import Blog from "../components/Blog/Blog"
import Layout from "../components/Base/Layout"

export default function({ pageContext }) {
  return (
    <Layout>
      <Blog slug={pageContext.slug} />
    </Layout>
  )
}
